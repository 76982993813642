import {
  QtmButton,
  QtmModal,
  QtmModalBody,
  QtmModalDivider,
  QtmModalFooter,
  QtmModalHeader,
  QtmModalTitle,
  QtmToggleSwitch,
  QtmTypography,
} from '@qtm/react';
import { useAcceptCookiesMutation, useRejectCookesMutation } from 'api/UserEndPoints';
import { useState } from 'react';
import ShowHideContent from 'shared/compoments/layout/ShowHideContent';

const PrivacyPreferenceCenter = () => {
  const [accept, { isLoading: isAccepting }] = useAcceptCookiesMutation();
  const [reject, { isLoading: isRejecting }] = useRejectCookesMutation();

  const [showMore, setShowMore] = useState(false);

  const [acceptCookies, setAcceptCookies] = useState(true);

  const isLoading = isAccepting || isRejecting;

  const onClick = (e: any) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const onToggleCookies = (e: any) => {
    setAcceptCookies(e.detail.checked);
  };

  const onRejects = () => {
    reject();
  };

  const onConfirm = () => {
    if (acceptCookies) {
      accept();
    } else {
      reject();
    }
  };

  return (
    <QtmModal open overlay size="medium" onCloseModal={onRejects}>
      <QtmModalHeader closeIcon>
        <QtmModalTitle>
          <div>
            <img alt="thales logo" src="/logo_dark.svg" />
            <div className="mt-m">
              <QtmTypography>Privacy Preference center</QtmTypography>
            </div>
          </div>
        </QtmModalTitle>
      </QtmModalHeader>
      <QtmModalBody>
        <div className="text-left">
          When you visit any website, it may store or retrieve information on your browser, mostly
          in the form of cookies. This information might be about you, your preferences or your
          device and is mostly used to make the site work as you expect it to. The information does
          not usually directly identify you, but it can give you a more personalized web experience.
          Because we respect your right to privacy, you can choose not to allow some types of
          cookies. Click on the different category headings to find out more and change our default
          settings. However, blocking some types of cookies may impact your experience of the site
          and the services we are able to offer.More information
        </div>
        <div>
          <QtmButton variant="ghost" color="neutral" onClickEvent={onClick} classes="mt-s">
            More Information
          </QtmButton>
          {showMore && (
            <div className="text-left mt-m">
              <div className="text-scale-6">Manage Consent Préférences</div>
              <div className="mt-s">
                <ShowHideContent
                  defaultShow
                  title={
                    <div className="flex justify-between">
                      <div className="font-bold">Strictly Necessary Cookies</div>
                      <div>Always Active</div>
                    </div>
                  }
                >
                  These cookies are necessary for the website to function and cannot be switched off
                  in our systems. They are usually only set in response to actions made by you which
                  amount to a request for services, such as setting your privacy preferences,
                  logging in or filling in forms. You can set your browser to block or alert you
                  about these cookies, but some parts of the site will not then work. These cookies
                  do not store any personally identifiable information.
                </ShowHideContent>
              </div>
              <div className="mt-s">
                <ShowHideContent
                  title={
                    <div className="flex justify-between">
                      <div className="font-bold">Performance Cookies</div>
                      <div>
                        <QtmToggleSwitch
                          color="primary"
                          labelPosition="left"
                          checked={acceptCookies}
                          onValueChanged={onToggleCookies}
                        >
                          &nbsp;
                        </QtmToggleSwitch>
                      </div>
                    </div>
                  }
                >
                  These cookies allow us to count visits and traffic sources so we can measure and
                  improve the performance of our site. They help us to know which pages are the most
                  and least popular and see how visitors move around the site. All information these
                  cookies collect is aggregated and therefore anonymous. If you do not allow these
                  cookies we will not know when you have visited our site, and will not be able to
                  monitor its performance.
                </ShowHideContent>
              </div>
            </div>
          )}
        </div>
      </QtmModalBody>
      <QtmModalDivider />
      <QtmModalFooter classes="justify-between">
        <QtmButton color="primary" variant="ghost" onClickEvent={onRejects} disabled={isLoading}>
          Reject All
        </QtmButton>
        <QtmButton color="primary" variant="filled" onClickEvent={onConfirm} disabled={isLoading}>
          Confirm My Choices
        </QtmButton>
      </QtmModalFooter>
    </QtmModal>
  );
};

export default PrivacyPreferenceCenter;
