import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  SilentRequest,
} from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { QtmProgressCircle } from '@qtm/react';
import { Ion } from 'cesium';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Landing from 'modules/Landing/Landing';
import { useEffect } from 'react';
import { setBearerToken } from 'shared/reducers/appSlice';

Ion.defaultAccessToken = import.meta.env.VITE_CESIUM_TOKEN;

const AzureSecuredApp = () => {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(state => state.app.bearerToken !== '');
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (!connected && inProgress === InteractionStatus.None && accounts.length > 0) {
      const accessTokenRequest: SilentRequest = {
        scopes: ['user.read'],
        forceRefresh: true,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          let token = accessTokenResponse.idToken;
          if (token) {
            dispatch(setBearerToken(token));
          }
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, connected]);

  if (inProgress === InteractionStatus.Startup || !connected) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <QtmProgressCircle classes="" track size="xlarge" />
      </div>
    );
  }

  return (
    <AuthenticatedTemplate>
      <Landing />
    </AuthenticatedTemplate>
  );
};

export default AzureSecuredApp;
