import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import { intersects } from '@arcgis/core/geometry/geometryEngine';
import { useGetAreasQuery } from 'api/AreaOfInterestEndPoints';
import isNumber from 'lodash/isNumber';
import { GeomPoint } from 'shared/model/AreaOfInterest.model';

export const useGetPoiOfArea = (areaSelected: any) => {
  const { data } = useGetAreasQuery(undefined, {
    skip: !areaSelected,
  });

  const areas = data ?? [];

  const zoi = areaSelected ? Graphic.fromJSON(areaSelected) : null;
  if (!zoi) {
    return [];
  }

  return areas.reduce((acc: Graphic[], area) => {
    const geom = area.geom as GeomPoint;
    if (isNumber(geom.latitude) && isNumber(geom.longitude)) {
      if (area.geopolitique === zoi.getAttribute('geopolitique')) {
        const geometry = new Point({
          latitude: geom.latitude,
          longitude: geom.longitude,
          spatialReference: {
            wkid: 3857,
          },
        });
        const isIn = intersects(zoi.geometry, geometry);

        if (isIn) {
          const graphic = new Graphic({
            geometry,
            attributes: {
              id: area.id,
              name: area.name,
            },
          });

          acc.push(graphic);
        }
      }
    }

    return acc;
  }, []);
};
