import { QtmButton, QtmIcon } from '@qtm/react';
import React, { useState } from 'react';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  defaultShow?: boolean;
};

const ShowHideContent = ({ defaultShow = false, title, children }: Props) => {
  const [show, setShow] = useState(defaultShow);

  const toggle = () => {
    setShow(!show);
  };

  const icon = show ? 'remove' : 'add';

  return (
    <div>
      <div className="flex items-center mb-s">
        <QtmButton variant="ghost" color="neutral" onClickEvent={toggle}>
          <QtmIcon icon={icon} />
        </QtmButton>
        <div className="grow">{title}</div>
      </div>
      {show && <div>{children}</div>}
    </div>
  );
};

export default ShowHideContent;
