import { QtmProgressCircle } from '@qtm/react';
import { useGetTokenUserQuery } from 'api/tokenEndPoints';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Landing from 'modules/Landing/Landing';
import { useEffect } from 'react';
import { setToken } from 'shared/reducers/appSlice';

type Props = {
  token: string;
};

const TokenSecuredApp = ({ token }: Props) => {
  const dispatch = useAppDispatch();

  const connected = useAppSelector(state => state.app.token !== '');

  const { data, isLoading, isSuccess } = useGetTokenUserQuery(token);

  useEffect(() => {
    console.log('Token authentication');
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setToken(token));
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <QtmProgressCircle classes="" track size="xlarge" />
      </div>
    );
  }

  if (!connected && isSuccess && !data) {
    return <div>Not allowed</div>;
  }

  if (!data) {
    return null;
  }

  return <Landing />;
};

export default TokenSecuredApp;
