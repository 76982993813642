import { api } from 'api/api';
import { User } from 'shared/model/user.model';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['user'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getCurrentUser: builder.query<User, void>({
        query: () => ({
          url: `/users/current`,
          method: 'GET',
        }),
        providesTags: ['user'],
      }),
      acceptCookies: builder.mutation<User, void>({
        query: () => ({
          url: `/users/current/acceptCookies`,
          method: 'PATCH',
        }),
        invalidatesTags: ['user'],
      }),
      rejectCookes: builder.mutation<User, void>({
        query: () => ({
          url: `/users/current/rejectCookies`,
          method: 'PATCH',
        }),
        invalidatesTags: ['user'],
      }),
    }),
  });

export const { useGetCurrentUserQuery, useAcceptCookiesMutation, useRejectCookesMutation } =
  extendedApi;
